import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from "reactstrap";

// core components

import ProgrammesHeader from "components/ProgrammesHeader.js";
import Footer from "components/Footer.js";
import { Link } from "react-router-dom";

const Data = [
  {
    name: "OPJEMS Scholarship",
    link: "/opjems",
  },
  {
    name: "DAAD WISE Scholarship",
    link: "/daad",
  },
  {
    name: "EPFL Research Internship",
    link: "/epfl",
  }

];

export default function Programmes() {
  const [pills, setPills] = React.useState("1");
  React.useEffect(() => {
    document.title = "Programmes and Scholarships | Research Wing | AnC";
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>

      <div className="wrapper">
        <ProgrammesHeader />
        <div className="section">
          <Container>
            {/* <div className="button-container">
              <Button className="btn-round" color="info" size="lg">
                Follow
              </Button>
              <Button
                className="btn-round btn-icon"
                color="default"
                id="tooltip515203352"
                size="lg"
              >
                <i className="fab fa-twitter"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip515203352">
                Follow me on Twitter
              </UncontrolledTooltip>
              <Button
                className="btn-round btn-icon"
                color="default"
                id="tooltip340339231"
                size="lg"
              >
                <i className="fab fa-instagram"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip340339231">
                Follow me on Instagram
              </UncontrolledTooltip>
            </div> */}
            <h3 className="title">About </h3>
            <h5 className="title" style={{ fontWeight: 200 }}>
              The Following Page contains resources from the Research Wing,AnC for
              various scholarships
            </h5>
            {/* <Row>
              <Col className="ml-auto mr-auto" md="6">
                <h4 className="title text-center">Programmes</h4> */}
            {/* <div className="nav-align-center">
                    <Tabs></Tabs>
                  </div> */}
            {/* </Col>
            </Row>
            <Tabs />
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <h4 className="title text-center">Scholarships</h4> */}
            {/* <div className="nav-align-center">
                    <Tabs></Tabs>
                  </div> */}
            {/* </Col>
            </Row>
            <Tabs /> */}
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <div className="nav-align-center">
                  <Nav
                    className="justify-content-center"
                    tabs
                    role="tablist"
                  // data-background-color="blue"
                  >
                    <NavItem>
                      <NavLink
                        className={pills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("1");
                        }}
                      >
                        {/* <i className="now-ui-icons design_image"></i> */}
                        Scholarships
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={pills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("2");
                        }}
                      >
                        <i className="now-ui-icons location_world"></i>
                        Programmes
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "3" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("3");
                        }}
                      >
                        <i className="now-ui-icons sport_user-run"></i>
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </div>
              </Col>
            </Row>
            <TabContent className="gallery" activeTab={"pills" + pills}>
              <TabPane tabId="pills1">
                {/* <Col className="ml-auto mr-auto" md="10">
                    <Row className="collections">
                      <Col md="6">
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/bg1.jpg")}
                        ></img>
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/bg3.jpg")}
                        ></img>
                      </Col>
                      <Col md="6">
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/bg8.jpg")}
                        ></img>
                        <img
                          alt="..."
                          className="img-raised"
                          src={require("assets/img/bg7.jpg")}
                        ></img>
                      </Col>
                    </Row>
                  </Col> */}

                <Container>
                  <Row>
                    <Card>
                      <CardBody>
                        <TabContent
                          className="text-left"
                          activeTab={"iconPills"}
                        >
                          <TabPane tabId="iconPills">
                            <p class="h5" color="dark">
                              <ul>
                                {Data.map((resource) => {
                                  return (
                                    <li>
                                      <Link
                                        to={resource.link}
                                        style={{
                                          textDecoration: "none",
                                          color: "black",
                                        }}
                                      >
                                        {resource.name}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </p>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Row>
                </Container>
              </TabPane>
              <TabPane tabId="pills2">
                <Col className="ml-auto mr-auto" md="10">
                  <Row className="collections">
                    <Col md="6">
                      <img
                        alt="..."
                        className="img-raised"
                        src={require("assets/img/bg6.jpg")}
                      ></img>
                      <img
                        alt="..."
                        className="img-raised"
                        src={require("assets/img/bg11.jpg")}
                      ></img>
                    </Col>
                    <Col md="6">
                      <img
                        alt="..."
                        className="img-raised"
                        src={require("assets/img/bg7.jpg")}
                      ></img>
                      <img
                        alt="..."
                        className="img-raised"
                        src={require("assets/img/bg8.jpg")}
                      ></img>
                    </Col>
                  </Row>
                </Col>
              </TabPane>
              <TabPane tabId="pills3">
                <Col className="ml-auto mr-auto" md="10">
                  <Row className="collections">
                    <Col md="6">
                      <img
                        alt="..."
                        className="img-raised"
                        src={require("assets/img/bg3.jpg")}
                      ></img>
                      <img
                        alt="..."
                        className="img-raised"
                        src={require("assets/img/bg8.jpg")}
                      ></img>
                    </Col>
                    <Col md="6">
                      <img
                        alt="..."
                        className="img-raised"
                        src={require("assets/img/bg7.jpg")}
                      ></img>
                      <img
                        alt="..."
                        className="img-raised"
                        src={require("assets/img/bg6.jpg")}
                      ></img>
                    </Col>
                  </Row>
                </Col>
              </TabPane>
            </TabContent>
          </Container>

          {/* <Tabs></Tabs> */}
        </div>
        <Footer />
      </div>
    </>
  );
}
